// app/components/ToastProvider.tsx
import React, { createContext, useContext, useState } from "react";

type Toast = { message: string; type: "success" | "error" };
type ToastContextType = {
      toast: Toast | null;
      showToast: (message: string, type: "success" | "error") => void;
      clearToast: () => void;
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
      const [toast, setToast] = useState<Toast | null>(null);

      const showToast = (message: string, type: "success" | "error") => {
            setToast({ message, type });
            setTimeout(() => setToast(null), 3000); // Auto-hide after 3 seconds
      };

      const clearToast = () => setToast(null);

      return (
            <ToastContext.Provider value={{ toast, showToast, clearToast }}>
                  {children}
                  {toast && (
                        <div
                              style={{
                                    position: "fixed",
                                    top: 20,
                                    right: 20,
                                    padding: "10px 20px",
                                    backgroundColor: toast.type === "success" ? "green" : "red",
                                    color: "white",
                                    borderRadius: 5,
                              }}
                        >
                              {toast.message}
                        </div>
                  )}
            </ToastContext.Provider>
      );
};

export const useToast = () => {
      const context = useContext(ToastContext);
      if (!context) {
            throw new Error("useToast must be used within a ToastProvider");
      }
      return context;
};
